import styled from "@emotion/styled"
import React from "react"
import colors from "../../../styles/colors"
import { HoverButton } from "../../button"
import Card from "../../card"
import Typography from "../../typography"

const Button = styled(HoverButton)`
  margin-top: 4rem;
  width: 330px;
  height: 90px;
  border-radius: 1000px;
  justify-content: center;
`

export const CardMoreInfo = ({
  title,
  buttonText,
  scrollToRefPoint,
  onClick,
}) => (
  <Card variant="white" center>
    <Typography variant="h2">
      {title ? (
        title
      ) : (
        <>
          Want to know more?
          <br />
          <span style={{ color: colors.primary }}>Let us show you.</span>
        </>
      )}
    </Typography>
    <Button
      onClick={() =>
        onClick
          ? onClick()
          : window.scrollTo({
              top: scrollToRefPoint || 0,
              left: 0,
              behavior: "smooth",
            })
      }
    >
      {buttonText ? buttonText : "Request a demo"}
    </Button>
  </Card>
)
