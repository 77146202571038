import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Card from "../../components/card"
import styled from "@emotion/styled"
import Typography from "../../components/typography"
import colors from "../../styles/colors"
import { CardMoreInfo } from "../../components/cards/requestDemo/cardMoreInfo"
import { MobileMediaQuery } from "../../styles/constants"
import { HoverButton } from "../../components/button"
import { Calendly } from "../../components/calendly"
import CardPartnersB2b from "../../components/cards/cardPartnersB2b"

const FullWidthAlignment = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${p => (p.rightComponent ? "space-between" : "center")};
  text-align: ${p => (p.centerText ? "center" : "left")};

  ${MobileMediaQuery} {
    flex-direction: column;
    align-items: center;
  }
`
const Left = styled.div`
  max-width: ${p => (p.leftMaxWidth ? p.leftMaxWidth : "970px")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${MobileMediaQuery} {
    text-align: center;
    margin-bottom: 3rem;
  }
`
const Right = styled.div`
  margin-left: 4rem;
  flex: 1;

  ${MobileMediaQuery} {
    margin-left: 0;
  }
`
const Button = styled(HoverButton)`
  margin-top: 4rem;
  width: 330px;
  height: 90px;
  border-radius: 1000px;
  justify-content: center;
  font-weight: 400;
`

const FullWidthCard = styled(Card)`
  padding-left: 8px !important;
  padding-right: 8px !important;
`

const FullWidth = React.forwardRef(
  (
    {
      title,
      description,
      variant,
      titleColor,
      leftMaxWidth,
      centerText = false,
      rightComponent,
      button,
    },
    ref
  ) => (
    <FullWidthCard
      variant={variant || "lightpurple"}
      style={{
        width: "100vw",
        maxWidth: "100vw",
        borderRadius: 0,
        marginLeft: "-4px",
        marginRight: "-4px",
      }}
    >
      <FullWidthAlignment
        centerText={centerText}
        rightComponent={rightComponent}
      >
        <Left leftMaxWidth={leftMaxWidth}>
          <Typography
            variant="h2"
            style={{
              color: titleColor ? colors[titleColor] : colors.primary,
              marginBottom: "4rem",
            }}
          >
            {title}
          </Typography>
          <Typography variant="h6">{description}</Typography>
          {button}
        </Left>
        {rightComponent && <Right ref={ref}>{rightComponent}</Right>}
      </FullWidthAlignment>
    </FullWidthCard>
  )
)

const Assessment = () => {
  const scrollRef = React.createRef()

  const scrollToCalendar = React.useCallback(() => {
    const top = scrollRef.current.getBoundingClientRect().top
    const scrollValue = top + window.pageYOffset
    window.scrollTo({ top: scrollValue - 80, behavior: "smooth" })
  }, [scrollRef])

  return (
    <Layout bg="white" newsletter={null}>
      <SEO
        title="Free service assessment"
        meta={[{ name: "robots", content: "noindex" }]}
      />
      <FullWidth
        centerText
        title="Get your free service assessment"
        button={
          <Button onClick={scrollToCalendar}>Book your assessment</Button>
        }
        description={
          <>
            Let us do the heavy lifting to review your support systems.
            <br />
            <br />
            We’ll look for repetitive troubleshooting issues, service area gaps,
            and more to uncover where your customer support teams can drive
            efficiency improvements.
          </>
        }
      />
      <CardPartnersB2b variant="white" />
      <FullWidth
        ref={scrollRef}
        titleColor="lightpurple"
        variant="primary"
        leftMaxWidth="600px"
        title={"Get a personalized service assessment from our team"}
        description={
          <>
            Schedule a call with one of our specialists who will work with you
            to identify areas of improvement within your support setup.
            <br /> <br /> Our team will gather information, build a personal
            assessment, and walk you through our recommendations— all for free.
          </>
        }
        rightComponent={
          <Calendly url="https://calendly.com/jonathankoo/free-service-assessment" />
        }
      />
      <CardMoreInfo onClick={scrollToCalendar} />
    </Layout>
  )
}

export default Assessment
